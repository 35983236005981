<template>
  <a-layout-sider
    width="200"
    :style="{ overflow: 'auto' }"
    style="background: #626a85;"
  >
    <a-menu
      mode="inline"
      :default-selected-keys="[Number(getNavNum)]"
      :style="{ height: '100%', borderRight: 0 }"
    >
      <template v-for="(item, index) in getRouters.children">
        <a-menu-item
          v-show="getRouters.children[index].children.length == 0"
          @click="router(item.component, item.dbId)"
          :key="item.dbId"
        >
          <a-icon v-if="item.icon" :type="item.icon" />

          {{ item.menu_name }}
        </a-menu-item>
        <a-sub-menu
          v-show="getRouters.children[index].children.length != 0"
          :key="`sub${item.dbId}`"
        >
          <span slot="title" @click="router(item.component)">
            <a-icon :type="item.icon" />
            <span>{{ item.menu_name }}</span>
          </span>
          <a-menu-item
            @click="router(itemA.component, itemA.dbId)"
            v-for="itemA in item.children"
            :key="itemA.dbId"
          >
            {{ itemA.menu_name }}
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      userrole: ""
      // navNum: ""
    };
  },
  computed: {
    routes() {
      let route = [];
      let path = this.$router.options.routes;
      path.forEach(val => {
        if (!val.hidden) {
          route = val.children;
        }
      });
      return route;
    },
    ...mapGetters(["getRouters", "getNavNum"])
  },
  created() {
    this.userrole = localStorage.getItem("admin");
    localStorage.getItem("navNum")
      ? this.$store.commit("setNavNum", localStorage.getItem("navNum"))
      : "";
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      if (to.path == from.path) {
        this.$router.go(0);
      }
      // this.$router.go(0)
    }
  },
  methods: {
    //跳转
    router(x, dbId) {
      this.$store.commit("setNavNum", dbId);
      this.$router.push({
        path: x
      });
      // for (let i = 0; i < this.routes.length; i++) {
      //   if (x == this.routes[i].meta.index) {
      //     this.$router.push({
      //       path: this.routes[i].meta.to
      //     });
      //     break;
      //   }
      // }
    },
    qq() {
      window.location.href = "https://www.baidu.com/";
    }
  }
};
</script>

<style scoped>
.el-menu-vertical-demo {
  border-right: 0px;
}
.menu-list::-webkit-scrollbar {
  display: none;
}
.el-menu-vertical-demo::-webkit-scrollbar {
  display: none;
}
.itemtitle {
  color: #ffffff;
  width: 100%;
  text-align: left;
}
.el-menu-vertical-demo {
  width: 200px;
}
.el-submenu__title {
  width: 200px !important;
}
.el-menu-item {
  width: 200px;
}
</style>

<template>
  <div class="layout-content">
    <a-layout id="components-layout-demo-top-side-2">
      <a-layout-header class="header">
        <img class="logo" style="display:none" :src="getSystemLogo" alt="" />
        <div class="systemname">{{ getSystemName }}</div>
        <div class="navBtn-list-con">
          <div class="navBtn-list flex align-center">
            <div
              v-for="(item, index) in getMenus"
              :key="index"
              :class="active == index ? 'navBtn-active' : 'navBtn'"
              @click="activeFn(index)"
            >
              {{ item.menu_name }}
            </div>
          </div>
        </div>

        <div class="header-right">
          <!-- <div class="header-right-notic">
            <div class="header-right-notic-num">1</div>
          </div> -->
          <div class="header-right-user flex align-center">
            <img
              src="../../assets/img/layout/admin_icon.png"
              class="header-right-user-img"
            />
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                {{ userName }} <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="changePassword()">修改密码</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="goout()">退出登陆</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <!-- <div class="login-out" @click="goout()">退出</div> -->
        </div>
      </a-layout-header>

      <a-layout>
        <menuNav></menuNav>
        <a-layout style="padding: 0 24px 24px">
          <keep-alive>
            <a-breadcrumb style="margin: 16px 0">
              <!-- <a-breadcrumb-item>Home</a-breadcrumb-item> -->
              <a-breadcrumb-item
                v-for="(items, index) in breadList"
                :key="index"
                >{{ items.meta.title }}</a-breadcrumb-item
              >
            </a-breadcrumb>
          </keep-alive>

          <a-layout-content
            :style="{
              margin: 0,
              minHeight: '280px',
              overflow: 'initial'
            }"
          >
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import menuNav from "./components/menu";
import { mapGetters } from "vuex";
export default {
  components: {
    menuNav
  },
  data() {
    return {
      collapsed: false,
      userName: "",
      userrole: "",
      systemName: "",
      breadList: [], //面包屑路由
      active: 0, //一级导航选中状态
      routers: [], //总路由
      useRouter: [], //显示侧导航路由
      menus: [] //权限路由
    };
  },
  onLoad() {
    this.userName = localStorage.getItem("userName");
  },
  created() {
    this.systemName = localStorage.getItem("system_name");
    this.active = localStorage.getItem("nav")
      ? localStorage.getItem("nav")
      : "";

    this.menus = localStorage.getItem("menus")
      ? JSON.parse(localStorage.getItem("menus"))
      : [];

    this.useRouter = localStorage.getItem("routers")
      ? JSON.parse(localStorage.getItem("routers"))
      : [];
    this.$store.commit("setRouters", this.useRouter);
    this.breadList = localStorage.getItem("breadList")
      ? JSON.parse(localStorage.getItem("breadList"))
      : [];
    this.userName = localStorage.getItem("userName")
      ? localStorage.getItem("userName")
      : this.userName;
    this.userrole = localStorage.getItem("admin");
    this.routers = this.$router.options.routes;
    this.$store.commit("setMenus", this.menus);
    this.menus = this.getMenus;
  },
  watch: {
    // $route(val) {
    //   // 调用获取路由数组方法
    //   this.getBreadList(val);
    // }
    $route: "getBreadList"
  },
  computed: {
    ...mapGetters(["getSystemName", "getSystemLogo", "getMenus"])
  },
  methods: {
    //一级导航选中状态
    activeFn(index) {
      console.log("view----------------------");
      this.active = index;
      localStorage.setItem("nav", index);
      // this.useRouter = this.menus[index];
      this.useRouter = this.getMenus[index];
      this.$store.commit("setRouters", this.useRouter);
      let routers = JSON.stringify(this.useRouter);
      localStorage.setItem("routers", routers);
      console.log(this.useRouter);
      let path = "";
      if (
        this.useRouter.children.length > 0 &&
        this.useRouter.children[0].component
      ) {
        this.$store.commit("setNavNum", this.useRouter.children[0].dbId);
        path = this.useRouter.children[0].component;
      } else {
        this.$store.commit(
          "setNavNum",
          this.useRouter.children[0].children[0].dbId
        );
        path = this.useRouter.children[0].children[0].component;
      }
      this.$router.push({
        path: path
      });
    },
    goout() {
      localStorage.removeItem("token");
      localStorage.removeItem("admin");
      localStorage.removeItem("userName");
      this.$router.push({
        name: "login"
      });
    },
    //修改密码路由跳转
    changePassword(){
      this.$router.push({
        name: "userspw"
      });
    },
    getBreadList(val) {
      // 过滤路由matched对象
      if (val.matched) {
        this.breadList = [];
        let matched = val.matched.filter(
          item => item.meta && item.meta.title && !item.hidden
        );
        matched.map(item => {
          this.breadList = this.breadList.concat({ meta: item.meta });
        });
        // this.breadList = matched;
        let breadList = JSON.stringify(this.breadList);
        localStorage.setItem("breadList", breadList);
      }
    }
  }
};
</script>

<style scoped lang="less">
.header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  background: #1890ff;
  padding: 0px 30px !important;
}
.navBtn-list-con {
  flex: 1;
  overflow: scroll;
  overflow-y: hidden;
}
.navBtn-list-con::-webkit-scrollbar {
  display: none;
}
.navBtn-list {
  flex: 1;
  width: max-content;
}
.navBtn {
  width: 120px;
  height: 72px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 72px;
  cursor: pointer;
}
.navBtn-active {
  width: 120px;
  height: 72px;
  background: #2872ce;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 72px;
  cursor: pointer;
}
.header-right {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}
.header-right-notic {
  width: 13px;
  height: 16px;
  position: relative;
  background: url("../../assets/img/layout/notice.png") no-repeat;
  background-size: 100% 100%;
  margin-right: 23px;
}
.header-right-notic-num {
  // width: 16px;
  padding: 0px 5px;
  height: 16px;
  border-radius: 8px;
  background: #fb4545;
  position: absolute;
  top: -8px;
  right: -8px;
  text-align: center;
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
}
.header-right-user {
  cursor: pointer;
}
.header-right-user-img {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}
.header-right-user-name {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-right: 6px;
}
.header-right-user-down {
  width: 10px;
  height: 7px;
}
.header-right-user-btns {
  position: absolute;
  z-index: 1;
  top: 63px;
  right: 0px;
}
.ant-dropdown-link {
  color: #ffffff;
}
#components-layout-demo-top-side-2 {
  min-height: 100vh;
}
#components-layout-demo-top-side-2 .logo {
  max-height: 60px;
}
#components-layout-demo-top-side-2 .systemname {
  margin: 0 28px 0 0;
  float: left;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  min-width: max-content;
}
.ant-layout-sider {
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  overflow: -moz-scrollbars-none;
}
/* 谷歌适用 */
.ant-layout-sider::-webkit-scrollbar {
  display: none;
}
</style>
